import { FC } from 'react'
import Image, { ImageProps } from 'next/image'

type Props = {
  icon: ImageProps['src']
  text: string
}

export const IconLabel: FC<Props> = ({ icon, text }) => (
  <div className='flex items-center ml-3 mr-3'>
    <div className='flex mr-3'>
      <Image src={icon} width={20} height={20} />
    </div>
    <div className='text-white font-bold'>{text}</div>
  </div>
)
