import React, { FC, useMemo } from 'react'
import tw from 'tailwind-styled-components'
import { useTranslation } from 'next-i18next'
import styled from 'styled-components'
import Link from 'next/link'
import { NavBar } from './Navigation/NavBar'
import { BackgroundImage } from './BackgroundImage'
import { IconLabel } from './IconLabel'
import { Button } from './Button'
import { useDeviceMediaQuery } from '../hooks'
import { clientOnlyComponent } from '../utils'
import { theme } from '../config'
import HeroImg from '@foros-fe/assets/images/hero.png'
import HeroMobileImg from '@foros-fe/assets/images/hero-mobile.png'
import BuyIcon from '@foros-fe/assets/icons/auction.png'
import GrowIcon from '@foros-fe/assets/icons/grow.png'
import SellIcon from '@foros-fe/assets/icons/sell.png'

const Container = tw.div`min-h-screen md:min-h-[796px] flex flex-col relative bg-green-700`
const Heading = tw.p`text-lg md:text-3xl lg:text-4xl font-bold`
const MiddleHeading = tw.p`text-5xl md:text-8xl xl:text-9xl font-bold`
const MainContainer = tw.div`
  container
  z-30
  flex
  flex-col
  items-center
  justify-start
  md:justify-around
  text-white
  text-center
  px-4
  xl:px-0
`
const GradientContainer = tw.div`
  hidden
  md:block
  z-20
  absolute
  bg-gradient-to-r
  left-0
  right-0
  bottom-0
  top-[68px]
  from-green-700
  via-transparent
  to-green-700
`
const HeroContainer = tw.div`z-10 absolute left-0 right-0 bottom-0 top-[68px]`
const InvertedButton = styled(Button)`
  border-color: ${theme.colors.white};
  color: ${theme.colors.white};
`

const HeroStatistic: FC<{ value: string; text: string }> = ({ value, text }) => (
  <div className='flex flex-col items-center mx-8'>
    <div className='font-bold text-4xl mb-4'>{value}</div>
    <div className='text-center'>{text}</div>
  </div>
)

const HeroBackground = clientOnlyComponent(() => {
  const { mobile } = useDeviceMediaQuery()

  return (
    <HeroContainer>
      <BackgroundImage src={mobile ? HeroMobileImg : HeroImg} priority />
    </HeroContainer>
  )
})

export const Hero: FC<{ onPrimaryClick?(): void }> = ({ onPrimaryClick }) => {
  const { t } = useTranslation('landing')
  const marketFeatures = useMemo(
    () => [
      {
        icon: BuyIcon,
        text: t('buy'),
      },
      {
        icon: GrowIcon,
        text: t('grow'),
      },
      {
        icon: SellIcon,
        text: t('sell'),
      },
    ],
    [t]
  )
  const statistics = useMemo(
    () => [
      {
        value: '€24M+',
        text: t('totalDealAmount'),
      },
      {
        value: '€17,599/ha',
        text: t('highestBid'),
      },
    ],
    [t]
  )

  return (
    <Container>
      <NavBar />
      <GradientContainer />
      <HeroBackground />
      <MainContainer>
        <div className='flex justify-center my-10'>
          {marketFeatures.map((feature) => (
            <IconLabel key={feature.text} {...feature} />
          ))}
        </div>
        <Heading className='mb-4'>{t('topHeading')}</Heading>
        <MiddleHeading className='mb-4'>{t('middleHeading')}</MiddleHeading>
        <Heading className='mb-6'>{t('bottomHeading')}</Heading>
        <p className='text-center md:mx-[24%] mb-8'>{t('subheading')}</p>
        <div className='mb-10 flex flex-col md:flex-row'>
          <Button
            value='hero-primary'
            onClick={onPrimaryClick}
            title={t('primaryCTA')}
            className='mr-0 md:mr-8 mb-4 md:mb-0'
          />
          <Link href={`/auctions`} passHref>
            <InvertedButton title={t('secondaryCTA')} value='hero-secondary' outline />
          </Link>
        </div>
        <div className='flex flex-col md:flex-row space-y-4 md:space-y-0 mb-10 justify-center'>
          {statistics.map((stat) => (
            <HeroStatistic key={stat.value} {...stat} />
          ))}
        </div>
      </MainContainer>
    </Container>
  )
}
