import { FC, HTMLAttributes } from 'react'
import tw from 'tailwind-styled-components'

type Props = HTMLAttributes<HTMLDivElement> & {
  active?: boolean
  title: string
}
type ActiveProps = TransientProps<Props, 'active'>

const TagContainer = tw.div<ActiveProps>`
  inline-block
  px-2
  py-[5px]
  rounded
  ${({ $active }) => ($active ? 'bg-green-100' : 'bg-red-100')}
`
const TagTitle = tw.p<ActiveProps>`
  font-bold
  text-xs
  uppercase
  ${({ $active }) => ($active ? 'text-green-500 animate-pulse' : 'text-red-500')}
`

export const ActiveTag: FC<Props> = ({ title, active, ...props }) => {
  return (
    <TagContainer $active={active} {...props}>
      <TagTitle $active={active}>{title}</TagTitle>
    </TagContainer>
  )
}
