import { ForwardedRef, forwardRef, useImperativeHandle, useRef } from 'react'
import ReactSelect, { Props as SelectProps } from 'react-select'
import styled from 'styled-components'
import tw from 'tailwind-styled-components'
import { theme } from '../../config'
import { useTranslation } from 'next-i18next'

export interface Props<Option = unknown, IsMulti extends boolean = false>
  extends SelectProps<Option, IsMulti> {
  label?: string
}
type Ref = { focus(): void }

const Container = tw.div`
  flex
  flex-col
  flex-1
  text-left
`
const StyledSelect = styled(ReactSelect)<Props>`
  & .Select__control {
    background-color: ${theme.colors.white};
    border-color: ${theme.colors.gray[400]};
    display: flex;
    flex: 1;
    box-shadow: none !important;
    height: 44px;
    border-radius: 8px;

    @media (max-width: 768px) {
      height: 40px;
    }
  }

  & .Select__control--is-focused,
  & .Select__control--menu-is-open {
    border-color: ${theme.colors.secondary[500]} !important;
  }

  & .Select__control:hover {
    background-color: ${theme.colors.white};
    border-color: ${theme.colors.gray[400]};
    width: 100%;
  }

  & .Select__single-value {
    color: ${theme.colors.gray[800]};
  }

  & .Select__placeholder {
    color: ${theme.colors.gray[600]};
  }

  & .Select__indicator-separator {
    display: none;
  }

  & .Select__indicator Select__dropdown-indicator {
    border-color: transparent transparent red;
  }

  & .Select__menu {
    z-index: 100;
  }

  & .Select__option {
    color: ${theme.colors.primary[500]};

    & :hover {
      background-color: ${theme.colors.gray[300]};
    }
  }

  & .Select__option--is-selected {
    background-color: ${theme.colors.gray[300]};

    & :hover {
      background-color: ${theme.colors.gray[400]};
    }
  }
` as typeof ReactSelect

const GenericSelect = <Option, IsMulti extends boolean = false>(
  { label, className, ...props }: Props<Option, IsMulti>,
  ref: ForwardedRef<Ref>
) => {
  const { t } = useTranslation('common')
  const selectRef = useRef(null)

  useImperativeHandle(
    ref,
    () => ({
      focus: () => (selectRef.current as Ref | null)?.focus(),
    }),
    [selectRef]
  )

  return (
    <Container className={className}>
      {label ? (
        <label className='text-sm mb-1 md:mb-2 font-semibold text-gray-800'>{label}</label>
      ) : null}
      <StyledSelect
        instanceId={label}
        classNamePrefix='Select'
        isSearchable={false}
        placeholder={t('choose')}
        openMenuOnFocus
        ref={selectRef}
        {...props}
      />
    </Container>
  )
}

export const Select = forwardRef(GenericSelect) as <Option, IsMulti extends boolean = false>(
  props: Props<Option, IsMulti> & { ref?: React.ForwardedRef<Ref> }
) => ReturnType<typeof GenericSelect>
