import { FC, memo } from 'react'
import tw from 'tailwind-styled-components'
import Image from 'next/image'
import { useDateTimeDuration } from '../hooks'
import { endTimestamp } from '../utils'
import ClockGreen from '@foros-fe/assets/icons/clock.png'

type Props = {
  endTime: string
  className?: string
  showTimestamp?: boolean
}

const Container = tw.div`flex flex-1 items-center`
const ImgContainer = tw.div`flex mr-3 min-w-[24px]`
const Text = tw.p`text-green-500 text-sm`

export const Countdown: FC<Props> = memo(({ className, endTime, showTimestamp = false }) => {
  const time = useDateTimeDuration(endTime)

  return (
    <Container className={className}>
      <ImgContainer>
        <Image src={ClockGreen} width={24} height={24} />
      </ImgContainer>
      <Text>
        {time}
        {showTimestamp ? <span className='text-gray-700'> [{endTimestamp(endTime)}]</span> : null}
      </Text>
    </Container>
  )
})
