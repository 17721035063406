import React, { FC } from 'react'
import { ImageProps } from 'next/image'
import { SvgIcon } from './SvgIcon'
import { ResponsiveImage } from './ResponsiveImage'
import Tick from '@foros-fe/assets/icons/tick-green.svg'

type Props = React.HTMLAttributes<HTMLDivElement> & {
  title: string
  icon: ImageProps['src']
  info: string
  bulletPoints: string[]
}

export const MarketSection: FC<Props> = ({ title, icon, info, bulletPoints }) => {
  return (
    <div className='flex flex-col mb-9 md:mb-0 last:mb-0 max-w-md mx-0 md:mx-5'>
      <div className='flex mb-3'>
        <div className='mr-2'>
          <ResponsiveImage src={icon} sizes={[24, 28]} />
        </div>
        <p className='text-black font-poppins text-xl md:text-2xl font-bold'>{title}</p>
      </div>
      <p className='hidden md:block text-gray-700 text-base leading-7 mb-5'>{info}</p>
      <div className='flex flex-col'>
        {bulletPoints.map((bp, i) => (
          <div key={`bp-${i}`} className='flex flex-row flex-1'>
            <div className='mr-3'>
              <SvgIcon src={Tick} size={15} className='py-1' />
            </div>
            <p className='text-gray-700 text-sm md:text-base leading-6 flex-1 mb-2'>{bp}</p>
          </div>
        ))}
      </div>
    </div>
  )
}
