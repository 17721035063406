import React, { FC } from 'react'
import Image, { ImageProps } from 'next/image'
import { useTranslation } from 'next-i18next'
import { Button } from './Button'

type Props = React.HTMLAttributes<HTMLElement> & {
  icon?: ImageProps['src']
  sectionTitle?: string
  title: string
  info: string
  onClick?(): void
}

export const SectionDesc: FC<Props> = ({
  sectionTitle,
  title,
  icon,
  info,
  className = '',
  onClick,
}) => {
  const { t } = useTranslation('common')

  return (
    <div className={`flex flex-col ${className}`}>
      {icon && sectionTitle && (
        <div className='flex flex-row mb-4 md:mb-6'>
          <div className='mr-3'>
            <Image src={icon} width={16} height={16} />
          </div>
          <p className='text-black font-poppins font-bold uppercase text-sm md:text-base'>
            {sectionTitle}
          </p>
        </div>
      )}
      <p className='text-2xl md:text-4xl text-black font-poppins mb-4 leading-snug'>{title}</p>
      <p className='text-gray-700 text-sm md:text-lg leading-6 md:leading-8 max-w-3xl mb-6'>
        {info}
      </p>
      <Button title={t('nav.signUpFree')} value='section-sign-up' onClick={onClick} />
    </div>
  )
}
