import { useMemo } from 'react'
import tw from 'tailwind-styled-components'
import { useTranslation } from 'next-i18next'
import { SectionDesc } from './SectionDesc'
import { SellingSection } from './SellingSection'
import { BackgroundImage } from './BackgroundImage'
import { useDeviceMediaQuery } from '../hooks'
import { clientOnlyComponent } from '../utils'
import SellIcon from '@foros-fe/assets/icons/sell-green.png'
import SellingCover from '@foros-fe/assets/images/selling-cover.png'
import TrackGolden from '@foros-fe/assets/icons/track-golden.png'
import BuyGolden from '@foros-fe/assets/icons/buy-golden.png'
import DealGolden from '@foros-fe/assets/icons/deal-golden.png'

const ContentContainer = tw.div<{ row?: boolean }>`
  container
  relative
  flex
  ${(p) => (p.row ? 'flex-row' : 'flex-col')}
  px-6
  md:px-0
`

export const SellingSections = clientOnlyComponent<{ onClick?(): void }>(({ onClick }) => {
  const { mobile } = useDeviceMediaQuery()
  const { t } = useTranslation('landing')
  const sellingSections = useMemo(
    () => [
      {
        title: t('sellingSection.tracking.heading'),
        icon: TrackGolden,
        info: t('sellingSection.tracking.content'),
      },
      {
        title: t('sellingSection.auction.heading'),
        icon: BuyGolden,
        info: t('sellingSection.auction.content'),
      },
      {
        title: t('sellingSection.deal.heading'),
        icon: DealGolden,
        info: t('sellingSection.deal.content'),
      },
    ],
    [t]
  )

  return (
    <div className='relative box-border border-gray-300'>
      {mobile ? (
        <div className='flex-col'>
          <ContentContainer className='py-14'>
            <SectionDesc
              className='items-center text-center max-w-xl'
              icon={SellIcon}
              sectionTitle={t('sellingSection.title')}
              title={t('sellingSection.heading')}
              info={t('sellingSection.content')}
              onClick={onClick}
            />
          </ContentContainer>
          <div className='w-screen relative'>
            <BackgroundImage src={SellingCover} />
            <ContentContainer className='py-14 text-center'>
              {sellingSections.map((s, i) => (
                <SellingSection key={`ss-${i}`} {...s} />
              ))}
            </ContentContainer>
          </div>
        </div>
      ) : (
        <>
          <div className='absolute z-10 top-0 bottom-0 left-0 right-2/4 bg-cover bg-center'>
            <BackgroundImage src={SellingCover} />
          </div>
          <div className='container flex py-48'>
            <div className='flex flex-1 flex-col mr-28'>
              {sellingSections.map((s, i) => (
                <SellingSection key={`ss-${i}`} {...s} />
              ))}
            </div>
            <div className='flex-1 ml-28'>
              <SectionDesc
                className='items-start max-w-xl'
                icon={SellIcon}
                sectionTitle={t('sellingSection.title')}
                title={t('sellingSection.heading')}
                info={t('sellingSection.content')}
                onClick={onClick}
              />
            </div>
          </div>
        </>
      )}
    </div>
  )
})
