import { clientOnlyComponent } from '../utils'
import Image, { ImageProps } from 'next/image'
import { useDeviceMediaQuery } from '../hooks'

type Props = Omit<ImageProps, 'sizes'> &
  (
    | {
        widths: [number, number]
        heights: [number, number]
        sizes?: never
      }
    | {
        sizes: [number, number]
        widths?: never
        heights?: never
      }
  )

export const ResponsiveImage = clientOnlyComponent<Props>(
  ({ sizes, widths, heights, ...props }) => {
    const { mobile } = useDeviceMediaQuery()
    const [mobileWidth, desktopWidth] = widths ?? sizes!
    const [mobileHeight, desktopHeight] = heights ?? sizes!

    return (
      <Image
        {...props}
        width={mobile ? mobileWidth : desktopWidth}
        height={mobile ? mobileHeight : desktopHeight}
      />
    )
  }
)
