import React, { FC } from 'react'
import { ImageProps } from 'next/image'
import { ResponsiveImage } from './ResponsiveImage'

type Props = React.HTMLAttributes<HTMLElement> & {
  icon: ImageProps['src']
  title: string
  info: string
}

export const SellingSection: FC<Props> = ({ title, icon, info, className = '' }) => {
  return (
    <div
      className={`flex flex-col md:flex-row items-center md:items-start z-30 mb-10 md:mb-14 last:mb-0 ${className}`}
    >
      <div className='mr-0 md:mr-9 mb-2 md:mb-0'>
        <ResponsiveImage src={icon} sizes={[30, 60]} />
      </div>
      <div className='flex flex-col flex-1'>
        <p className='text-white font-poppins text-xl md:text-2xl mb-2 md:mb-4 break-all'>
          {title}
        </p>
        <p className='text-white text-sm md:text-base md:leading-7'>{info}</p>
      </div>
    </div>
  )
}
