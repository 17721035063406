import {
  ListAuctionsQueryVariables,
  BaseMarketAuctionsQueryVariables,
  useBaseMarketAuctionsQuery,
  useListAuctionsQuery,
} from '../generated'
import { usePaginatedQuery } from './utils'

const PER_PAGE = 10

export const usePaginatedListAuctionsQuery = (variables: ListAuctionsQueryVariables) => {
  return usePaginatedQuery(
    useListAuctionsQuery,
    {
      variables: { first: PER_PAGE, ...variables },
      nextFetchPolicy: 'cache-only',
    },
    'auctions'
  )
}

export const usePaginatedBaseMarketAuctionQuery = (variables: BaseMarketAuctionsQueryVariables) => {
  return usePaginatedQuery(
    useBaseMarketAuctionsQuery,
    {
      variables: { first: PER_PAGE, ...variables },
      fetchPolicy: 'cache-first',
    },
    'marketAuctions'
  )
}
