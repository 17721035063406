import {
  FC,
  forwardRef,
  InputHTMLAttributes,
  RefAttributes,
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react'
import { FieldError, get, useFormContext } from 'react-hook-form'
import { renderToStaticMarkup } from 'react-dom/server'
import tw from 'tailwind-styled-components'
import { useTranslation } from 'next-i18next'
import styled from 'styled-components'
import ReactPhoneInput, { PhoneInputProps, CountryData } from 'react-phone-input-2'
import { ErrorMessages } from '../ErrorMessages'
import { theme } from '../../config'
import ArrowDown from '@foros-fe/assets/icons/arrow-down.svg'

import 'react-phone-input-2/lib/high-res.css'

type Props = Omit<InputHTMLAttributes<HTMLInputElement>, 'value' | 'name' | 'onChange'> & {
  name: string
  value?: string | null
  label?: string
  error?: FieldError
  languageCode?: string
  onChange(value: string | null): void
}
type ReactPhoneInputRef = { numberInputRef?: HTMLInputElement }

const Input = ReactPhoneInput as FC<PhoneInputProps & RefAttributes<ReactPhoneInputRef>>
const svgString = encodeURIComponent(renderToStaticMarkup(<ArrowDown />))

const Container = tw.div`flex flex-col flex-1 text-left`
const TwContainer = tw.div`
  flex
  items-center
  h-10
  md:h-11
  border
  rounded-lg
  focus-within:border-secondary-500
  w-full
`
const InputContainer = styled(TwContainer)<{ $disabled?: boolean; $error?: object }>`
  border-color: ${({ $error }) => ($error ? theme.colors.red[500] : theme.colors.gray[400])};
  background-color: ${({ $disabled }) => ($disabled ? theme.colors.gray[200] : theme.colors.white)};

  & .react-tel-input {
    display: flex;
    flex: 1;

    & .form-control {
      display: flex;
      flex: 1;
      background: ${theme.colors.white};
      color: ${theme.colors.gray[800]};
      border: none;
      @media only screen and (max-width: 640px) {
        font-size: 14px;
      }
      font-size: 16px;
      outline: none;
      padding-left: 74px;
      padding-right: 12px;
      width: 100%;
    }

    & .special-label {
      display: none;
    }

    & .flag-dropdown {
      background: ${theme.colors.white};
      border: none;
      border-radius: 8px 0 0 8px;
      padding-top: 5px;
      padding-bottom: 5px;
      &:hover,
      &:focus {
        cursor: pointer;
      }

      &.open {
        z-index: 2;
      }

      & .selected-flag {
        outline: none;
        position: relative;
        width: 66px;
        height: 100%;
        padding: 0 0 0 15px;
        border-right: 1px solid ${theme.colors.gray[400]};

        &:focus {
          .arrow {
            border-left-width: 4px;
            border-right-width: 4px;
          }
        }

        & .flag {
          position: absolute;
          top: 50%;
          margin-top: -12px;
        }

        & .arrow {
          position: relative;
          top: 52%;
          margin-top: -1px;
          left: 30px;
          width: 10px;
          height: 5px;
          background: url('data:image/svg+xml,${svgString}');
          border: none;

          &.up {
            transform: scaleY(-1);
            border-top: none;
          }
        }
      }
    }

    .country-list {
      outline: none;
      z-index: 1;
      list-style: none;
      position: absolute;
      padding: 0;
      margin: 10px 0 10px -1px;
      box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.35);
      background-color: ${theme.colors.white};
      width: 300px;
      overflow-y: scroll;
      border-radius: 4px;

      .country {
        padding: 7px 9px 7px 44px;
        position: relative;
        color: ${theme.colors.primary[500]};

        .dial-code {
          color: ${theme.colors.black};
        }

        &:hover {
          background-color: ${theme.colors.gray[300]};
        }

        &.highlight {
          background-color: ${theme.colors.gray[300]};

          &:hover {
            background-color: ${theme.colors.gray[400]};
          }
        }
      }
    }
  }
`
const Label = tw.label`
  text-sm
  font-semibold
  mb-1
  md:mb-2
  text-gray-800
`

export const PhoneInput: FC<Props> = forwardRef(
  (
    {
      label,
      name,
      className,
      disabled,
      error,
      languageCode,
      placeholder,
      onChange,
      value,
      ...props
    },
    ref
  ) => {
    const context = useFormContext()
    const inputRef = useRef<ReactPhoneInputRef>(null)
    const { t, i18n } = useTranslation('enum')
    const [country, setCountry] = useState<CountryData | null>(null)
    const localization = useMemo(
      () =>
        Object.fromEntries(
          Object.entries(t(`${'CountryCode'}`, { returnObjects: true })).map(([key, value]) => [
            key.toLowerCase(),
            value,
          ])
        ),
      [t]
    )
    const handleChange = useCallback(
      (value, country) => {
        setCountry(country)

        if ('dialCode' in country && country.dialCode === value) {
          return onChange(null)
        }

        onChange(value)
      },
      [onChange]
    )

    useImperativeHandle(
      ref,
      () => ({
        focus: () => inputRef.current?.numberInputRef?.focus(),
      }),
      [inputRef]
    )

    const fieldError = error || get(context?.formState.errors, name)

    return (
      <Container className={className}>
        {label && <Label>{label}</Label>}
        <InputContainer $disabled={disabled} $error={fieldError}>
          <Input
            country={languageCode?.toLowerCase() ?? i18n.language}
            placeholder={placeholder}
            countryCodeEditable={false}
            disabled={disabled}
            ref={inputRef}
            localization={localization}
            onChange={handleChange}
            value={value ?? country?.dialCode}
            {...props}
          />
        </InputContainer>
        <ErrorMessages error={fieldError} />
      </Container>
    )
  }
)
