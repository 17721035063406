import React, { FC, useCallback, useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'next-i18next'
import tw from 'tailwind-styled-components'
import {
  useCreateRoughPriceInquiryMutation,
  CreateRoughPriceInquiryInput,
  ForestType,
  TaxonomicPlotMaturityCondition,
} from '@foros-fe/graphql'
import { Form } from './Form'
import { Button } from '../Button'
import { Select } from '../Inputs/Select'
import { TextInput } from '../Inputs/TextInput'
import { CheckBox } from '../Inputs/CheckBox'
import { enumToOption, getErrorTranslations } from '../../utils'
import { RoughPriceInquiryModal } from '../RoughPriceInquiryModal'

const Title = tw.p`text-xl font-semibold self-start`

type Data = Pick<CreateRoughPriceInquiryInput, 'leafType' | 'maturity' | 'intents'> & {
  area: string
}

export const PriceCalculator: FC = () => {
  const { t } = useTranslation(['inquiries', 'auctions', 'enum'])
  const [modalOpen, setModalOpen] = useState(false)
  const [createRoughPriceInquiryMutation, { data }] = useCreateRoughPriceInquiryMutation()
  const form = useForm<Data>({ defaultValues: { intents: [] } })
  const { register, control, setError } = form
  const onSubmit = useCallback(
    async ({ area, ...input }: Data) => {
      const { data } = await createRoughPriceInquiryMutation({
        variables: {
          input: { ...input, area: parseFloat(area) },
        },
      })

      const error = data?.createRoughPriceInquiry?.error
      if (error) {
        if (error.intents) {
          setError(
            'intents',
            getErrorTranslations('CreateRoughPriceInquiryIntentsError', error.intents)
          )
        }
      } else {
        setModalOpen(true)
      }
    },
    [createRoughPriceInquiryMutation, setError]
  )
  const forestTypeOptions = useMemo(
    () =>
      enumToOption(ForestType).map(({ label, value }) => ({
        label: t(`enum:ForestType.${label}`),
        value,
      })),
    [t]
  )
  const maturityOptions = useMemo(
    () =>
      enumToOption(TaxonomicPlotMaturityCondition).map(({ label, value }) => ({
        label: t(`enum:TaxonomicPlotMaturityCondition.${label}`),
        value,
      })),
    [t]
  )

  const inquiryId = data?.createRoughPriceInquiry?.roughPriceInquiry?.id

  return (
    <>
      <Form {...form} onSubmit={onSubmit}>
        <div className='flex flex-col space-y-6'>
          <Title>{t('title')}</Title>
          <TextInput
            type='number'
            label={t('auctions:area')}
            placeholder={t('common:hectare')}
            {...register('area', { required: true })}
          />
          <Controller
            control={control}
            name='leafType'
            rules={{ required: true }}
            render={({ field }) => (
              <Select
                label={t('auctions:forestType')}
                options={forestTypeOptions}
                {...field}
                onChange={(option) => field.onChange(option?.value)}
                value={forestTypeOptions.find(({ value }) => value === field.value)}
              />
            )}
          />
          <Controller
            control={control}
            name='maturity'
            rules={{ required: true }}
            render={({ field }) => (
              <Select
                label={t('auctions:maturity')}
                options={maturityOptions}
                {...field}
                onChange={(option) => field.onChange(option?.value)}
                value={maturityOptions.find(({ value }) => value === field.value)}
              />
            )}
          />
          <div className='flex flex-col space-y-2'>
            <CheckBox
              label={t('roughPrice.sellIntent')}
              {...register('intents')}
              value='want_to_sell'
            />
            <CheckBox
              label={t('roughPrice.investIntent')}
              {...register('intents')}
              value='income_without_selling'
            />
            <CheckBox
              label={t('roughPrice.consultationIntent')}
              {...register('intents')}
              value='want_consultation'
            />
          </div>

          <Button type='submit' value='price-calculator' title={t('roughPrice.calculatePrice')} />
        </div>
      </Form>
      {inquiryId ? (
        <RoughPriceInquiryModal
          open={modalOpen}
          inquiryId={inquiryId}
          onClose={() => setModalOpen(false)}
        />
      ) : null}
    </>
  )
}
